@charset "UTF-8";

/* 20180407 sass化 */

/*
==================================================
テーブル共通
==================================================
*/

table {
  width: 100%;
  font-size: 1rem;
  margin: 10px 0;
  border-collapse: collapse;
  border-spacing: 0px;
}

th {
  background-color: #ebebeb;
  padding: 0;
}

td {
  background-color: #fff;
  padding: 0;
}

table, td, th {
  border: solid 1px #ccc;
}
/*
==================================================
枠無し
==================================================
*/
.noborder td {		
	border:none;		
}
/*
==================================================
display table
==================================================
*/
/* display table01 */
.table-box {
	display: table;
	width:100%;
	margin-bottom:15px;
}
.table-tr {
  display: table-row;
}
.table-img, .table-text {
	display: table-cell;
	vertical-align: middle;
	text-align:center;
}
.table-img img{
	width:300px;
}
@media screen and (max-width:640px) {
	.table-box{
	display: block;
	}
	.table-img, .table-text {
	display: block;
	}
	.table-img img {
	width: 100%;
	margin-bottom:10px;
	}
}
/* display table02(いずれ削除) */
.item_table{
	display:table;
	border-collapse: collapse;
	width:80%;
	margin:0 auto;
	table-layout:fixed;
}
.item_table .item_tablecell{
	display:table-cell;
	text-align:center;
}
.item_table .item_tablecell img{
	width:90%;
	vertical-align: middle;
}


/*
==================================================
r対応?
==================================================
*/

table#toptbl {
  border: none;
  td, th {
    border: none;
  }
}

#menu .ritem td, #rmenu .ritem td {
  border: none;
}

.rtitle {
  height: 44px;
  text-align: center;
  overflow: hidden;
  padding: 4px;
}

.rimg {
  text-align: center;
}

.timg img {
  max-width: 200px;
  height: auto;
}

.price {
  color: #c00;
  font-weight: bold;
}

.tdesc {
  text-align: left;
  vertical-align: top;
}

/*
==================================================
商品陳列型
==================================================
*/

.ritm {
  background-color: #fff;
  table-layout: fixed;
  th, td {
    padding: 6px;
    line-height: 1.6;
    vertical-align: top;
    text-align: center;
  }
}

/*
==================================================
特定取引法に関する表記
==================================================
*/

.tokusho {
  th, td {
    padding: 8px;
  }
  th {
    background-color: #E3E3E3;
    width: 25%;
  }
}

/*
==================================================
商品説明
==================================================
*/

.ritm_desc {
  td {
    vertical-align: top;
    padding: 10px;
  }
  .title {
    background-color: #ebebeb;
    padding: 5px;
    margin-bottom: 8px;
    text-align: left;
    line-height: 1.4;
    font-weight: normal;
    a {
      color: #333;
      &:hover {
        color: #f00;
      }
    }
  }
  .desc {
    padding: 20px;
  }
  .price {
    text-align: left;
    padding: 0px;
  }
  .img {
    width: 20%;
  }
  .more, .price, .review {
    line-height: 25px;
    height: 25px;
    margin-right: 5px;
    margin-left: 5px;
  }
}

/*
==================================================
ランキングテーブル１
==================================================
*/

table.rnk1item {
  table-layout: auto;
  border: solid 1px #ccc;
}

.rnk1item {
  td {
    border: none;
    border-bottom: dotted 1px #ccc;
  }
  th {
    border: none;
    border-bottom: dotted 1px #ccc;
    background-color: #FFF;
    width: auto;
    padding: 5px;
    width: 60px;
    vertical-align: middle;
  }
  td {
    background-color: #fff;
    vertical-align: top;
    padding: 15px 10px;
  }
  .ttitle {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 12px;
    line-height: 1.3;
	  color:navy;
  }
  .timg {
    width: auto;
    padding-top: 10px;
    vertical-align: middle;
  }
}

/*
==================================================
ランキングテーブル２
==================================================
*/

table.rnk2item {
  table-layout: auto;
  border: solid 1px #ccc;
}

.rnk2item {
  .timg, .tdesc {
    padding: 8px;
    vertical-align: top;
  }
  th.ttitle {
    padding: 10px;
    text-align: left;
  }
  td {
    overflow: hidden;
    &.tdesc {
      width: 100%;
    }
    &.timg {
      width: auto;
    }
    &.tdesc, &.timg {
      border: none;
    }
  }
  .timg img {
    padding-bottom: 10px;
    height: auto;
  }
  .tdesc {
    line-height: 1.6;
  }
}

table.rnk2item table.ttable {
  width: 101%;
  margin: 0px;
  padding: 0px;
  border: none;
  table-layout: fixed;
}

.ttable {
  th, td {
    padding: 8px;
    text-align: center;
    border: none;
    border-right: dotted 1px #ccc;
  }
}

/*
==================================================
ランキングテーブル３
==================================================
*/

.rnk3item {
  table-layout: auto;
  border: solid 1px #ccc;
  th {
    text-align: left;
    padding: 8px;
    img {
      vertical-align: bottom;
      margin-right: 6px;
    }
  }
  td {
    padding: 10px;
    border: none;
    &.timg {
      text-align: center;
      width: auto;
    }
  }
  .img-center {
    width: auto;
    display: inline;
  }
  th img {
    float: left;
  }
}

/*
==================================================
ランキングテーブル４  ▼独自追加分
==================================================
*/

.rnk4item {
  .timg {
    padding: 15px;
  }
}

.rnk2item .tdesc {
  padding: 15px;
}

.rnk4item {
  td {
    padding: 0px;
    vertical-align: top;
  }
  .ttitle {
    border: 1px solid #ccc;
    padding: 10px 10px 10px 30px;
    text-align: left;
    font-size: 1.2rem;
    line-height: 1.3;
  }
  .timg {
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: #ccc;
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: #ccc;
    text-align: center;
    width: 130px;
    img {
      padding-bottom: 10px;
    }
  }
  .tdesc {
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: #ccc;
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: #ccc;
    padding: 20px;
    text-align: left;
  }
  .tbutton {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #ccc;
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: #ccc;
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: #ccc;
    text-align: center;
  }
  .ttable {
    width: 100%;
    margin: 0px;
    padding: 0px;
    background-color: #ccc;
  }
}

.ttable {
  th, td {
    padding: 8px;
    width: 33%;
    text-align: center;
  }
}

/*
==================================================
価格比較テーブル
==================================================
*/

.prc1item {
  table-layout: auto;
  th {
    padding: 6px;
    text-align: center;
  }
  td {
    text-align: center;
    padding: 6px;
    text-align: center;
    padding: 6px;
  }
  .alt td {
    background-color: #F5F5F5;
  }
  .price {
    text-align: right;
    width: 100px;
    font-size: 15px;
  }
}

/*
==================================================
項目説明型
==================================================
*/

.dsc1item, .dsc2item {
  background-color: #ccc;
  table-layout: fixed;
}

.dsc1item {
  th, td {
    padding: 6px;
  }
}

.dsc2item {
  th, td {
    padding: 6px;
  }
}

.dsc1item th {
  width: 30%;
  text-align: center;
}

.dsc2item th {
  text-align: center;
}

.dsc1item td, .dsc2item td {
  vertical-align: top;
}

/*
==================================================
メニュー型
==================================================
*/

.tblmenu1item td {
  vertical-align: top;
  .ttitle {
    background-color: #eee;
    font-weight: bolder;
    padding: 7px;
    margin-bottom: 8px;
  }
}

/*
==================================================
スマートフォンへの最適化
==================================================
*/
@media screen and (max-width: 640px) {
  

  /* ランキング1 */
  .rnk1item {
    th, td {
      width: 100%;
      display: block;
      border: none;
    }
    tr {
      border-bottom: solid 1px #ccc;
    }
    .timg, th, .timg p, th p {
      text-align: center !important;
    }
  }
  /* ランキング2 */
  .rnk2item td {
    &.timg, &.tdesc {
      display: block;
      width: 100%;
      border-right: solid 1px #ccc;
    }
    &.timg {
      text-align: center !important;
    }
  }
  /* ランキング3 */
  .rnk3item {
    th, td {
      display: block;
      width: 100%;
    }
  }
  /* 特商ページ */
  .tokusho {
    td, th {
      display: block;
      width: 100%;
    }
  }
}