@charset "UTF-8";
/* 20180407 sass化 */
/*
==================================================
テーブル共通
==================================================
*/
table {
  width: 100%;
  font-size: 1rem;
  margin: 10px 0;
  border-collapse: collapse;
  border-spacing: 0px; }

th {
  background-color: #ebebeb;
  padding: 0; }

td {
  background-color: #fff;
  padding: 0; }

table, td, th {
  border: solid 1px #ccc; }

/*
==================================================
枠無し
==================================================
*/
.noborder td {
  border: none; }

/*
==================================================
display table
==================================================
*/
/* display table01 */
.table-box {
  display: table;
  width: 100%;
  margin-bottom: 15px; }

.table-tr {
  display: table-row; }

.table-img, .table-text {
  display: table-cell;
  vertical-align: middle;
  text-align: center; }

.table-img img {
  width: 300px; }

@media screen and (max-width: 640px) {
  .table-box {
    display: block; }
  .table-img, .table-text {
    display: block; }
  .table-img img {
    width: 100%;
    margin-bottom: 10px; } }

/* display table02(いずれ削除) */
.item_table {
  display: table;
  border-collapse: collapse;
  width: 80%;
  margin: 0 auto;
  table-layout: fixed; }

.item_table .item_tablecell {
  display: table-cell;
  text-align: center; }

.item_table .item_tablecell img {
  width: 90%;
  vertical-align: middle; }

/*
==================================================
r対応?
==================================================
*/
table#toptbl {
  border: none; }
  table#toptbl td, table#toptbl th {
    border: none; }

#menu .ritem td, #rmenu .ritem td {
  border: none; }

.rtitle {
  height: 44px;
  text-align: center;
  overflow: hidden;
  padding: 4px; }

.rimg {
  text-align: center; }

.timg img {
  max-width: 200px;
  height: auto; }

.price {
  color: #c00;
  font-weight: bold; }

.tdesc {
  text-align: left;
  vertical-align: top; }

/*
==================================================
商品陳列型
==================================================
*/
.ritm {
  background-color: #fff;
  table-layout: fixed; }
  .ritm th, .ritm td {
    padding: 6px;
    line-height: 1.6;
    vertical-align: top;
    text-align: center; }

/*
==================================================
特定取引法に関する表記
==================================================
*/
.tokusho th, .tokusho td {
  padding: 8px; }

.tokusho th {
  background-color: #E3E3E3;
  width: 25%; }

/*
==================================================
商品説明
==================================================
*/
.ritm_desc td {
  vertical-align: top;
  padding: 10px; }

.ritm_desc .title {
  background-color: #ebebeb;
  padding: 5px;
  margin-bottom: 8px;
  text-align: left;
  line-height: 1.4;
  font-weight: normal; }
  .ritm_desc .title a {
    color: #333; }
    .ritm_desc .title a:hover {
      color: #f00; }

.ritm_desc .desc {
  padding: 20px; }

.ritm_desc .price {
  text-align: left;
  padding: 0px; }

.ritm_desc .img {
  width: 20%; }

.ritm_desc .more, .ritm_desc .price, .ritm_desc .review {
  line-height: 25px;
  height: 25px;
  margin-right: 5px;
  margin-left: 5px; }

/*
==================================================
ランキングテーブル１
==================================================
*/
table.rnk1item {
  table-layout: auto;
  border: solid 1px #ccc; }

.rnk1item td {
  border: none;
  border-bottom: dotted 1px #ccc; }

.rnk1item th {
  border: none;
  border-bottom: dotted 1px #ccc;
  background-color: #FFF;
  width: auto;
  padding: 5px;
  width: 60px;
  vertical-align: middle; }

.rnk1item td {
  background-color: #fff;
  vertical-align: top;
  padding: 15px 10px; }

.rnk1item .ttitle {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12px;
  line-height: 1.3;
  color: navy; }

.rnk1item .timg {
  width: auto;
  padding-top: 10px;
  vertical-align: middle; }

/*
==================================================
ランキングテーブル２
==================================================
*/
table.rnk2item {
  table-layout: auto;
  border: solid 1px #ccc; }

.rnk2item .timg, .rnk2item .tdesc {
  padding: 8px;
  vertical-align: top; }

.rnk2item th.ttitle {
  padding: 10px;
  text-align: left; }

.rnk2item td {
  overflow: hidden; }
  .rnk2item td.tdesc {
    width: 100%; }
  .rnk2item td.timg {
    width: auto; }
  .rnk2item td.tdesc, .rnk2item td.timg {
    border: none; }

.rnk2item .timg img {
  padding-bottom: 10px;
  height: auto; }

.rnk2item .tdesc {
  line-height: 1.6; }

table.rnk2item table.ttable {
  width: 101%;
  margin: 0px;
  padding: 0px;
  border: none;
  table-layout: fixed; }

.ttable th, .ttable td {
  padding: 8px;
  text-align: center;
  border: none;
  border-right: dotted 1px #ccc; }

/*
==================================================
ランキングテーブル３
==================================================
*/
.rnk3item {
  table-layout: auto;
  border: solid 1px #ccc; }
  .rnk3item th {
    text-align: left;
    padding: 8px; }
    .rnk3item th img {
      vertical-align: bottom;
      margin-right: 6px; }
  .rnk3item td {
    padding: 10px;
    border: none; }
    .rnk3item td.timg {
      text-align: center;
      width: auto; }
  .rnk3item .img-center {
    width: auto;
    display: inline; }
  .rnk3item th img {
    float: left; }

/*
==================================================
ランキングテーブル４  ▼独自追加分
==================================================
*/
.rnk4item .timg {
  padding: 15px; }

.rnk2item .tdesc {
  padding: 15px; }

.rnk4item td {
  padding: 0px;
  vertical-align: top; }

.rnk4item .ttitle {
  border: 1px solid #ccc;
  padding: 10px 10px 10px 30px;
  text-align: left;
  font-size: 1.2rem;
  line-height: 1.3; }

.rnk4item .timg {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: #ccc;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: #ccc;
  text-align: center;
  width: 130px; }
  .rnk4item .timg img {
    padding-bottom: 10px; }

.rnk4item .tdesc {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: #ccc;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: #ccc;
  padding: 20px;
  text-align: left; }

.rnk4item .tbutton {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #ccc;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: #ccc;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: #ccc;
  text-align: center; }

.rnk4item .ttable {
  width: 100%;
  margin: 0px;
  padding: 0px;
  background-color: #ccc; }

.ttable th, .ttable td {
  padding: 8px;
  width: 33%;
  text-align: center; }

/*
==================================================
価格比較テーブル
==================================================
*/
.prc1item {
  table-layout: auto; }
  .prc1item th {
    padding: 6px;
    text-align: center; }
  .prc1item td {
    text-align: center;
    padding: 6px;
    text-align: center;
    padding: 6px; }
  .prc1item .alt td {
    background-color: #F5F5F5; }
  .prc1item .price {
    text-align: right;
    width: 100px;
    font-size: 15px; }

/*
==================================================
項目説明型
==================================================
*/
.dsc1item, .dsc2item {
  background-color: #ccc;
  table-layout: fixed; }

.dsc1item th, .dsc1item td {
  padding: 6px; }

.dsc2item th, .dsc2item td {
  padding: 6px; }

.dsc1item th {
  width: 30%;
  text-align: center; }

.dsc2item th {
  text-align: center; }

.dsc1item td, .dsc2item td {
  vertical-align: top; }

/*
==================================================
メニュー型
==================================================
*/
.tblmenu1item td {
  vertical-align: top; }
  .tblmenu1item td .ttitle {
    background-color: #eee;
    font-weight: bolder;
    padding: 7px;
    margin-bottom: 8px; }

/*
==================================================
スマートフォンへの最適化
==================================================
*/
@media screen and (max-width: 640px) {
  /* ランキング1 */
  .rnk1item th, .rnk1item td {
    width: 100%;
    display: block;
    border: none; }
  .rnk1item tr {
    border-bottom: solid 1px #ccc; }
  .rnk1item .timg, .rnk1item th, .rnk1item .timg p, .rnk1item th p {
    text-align: center !important; }
  /* ランキング2 */
  .rnk2item td.timg, .rnk2item td.tdesc {
    display: block;
    width: 100%;
    border-right: solid 1px #ccc; }
  .rnk2item td.timg {
    text-align: center !important; }
  /* ランキング3 */
  .rnk3item th, .rnk3item td {
    display: block;
    width: 100%; }
  /* 特商ページ */
  .tokusho td, .tokusho th {
    display: block;
    width: 100%; } }
